/*
* Base
*/
.logo[data-mobile-only] {
  @media (--tablet-s) {
    display: none;
  }
}
.logo[data-desktop-only] {
  @media (--mobile) {
    display: none;
  }
}

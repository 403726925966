.modalContainer {
  && {
    @media (--mobile) {
      height: auto;
      position: absolute;
      bottom: 0;
    }

    @media (--tablet) {
      width: 800px;
    }
  }
}

.body {
  && {
    padding: var(--spacing-8) var(--spacing-6);

    @media (--tablet) {
      padding: var(--spacing-8) var(--spacing-10);
    }
  }
}

.wrapper {
  && {
    & a {
      color: var(--color-neutral-700);
      font-weight: 700;
    }

    & p:first-child {
      margin-top: 0;
    }
  }
}

.ctasWrapper {
  && {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-3);
    margin-top: var(--spacing-1);

    @media (--tablet) {
      flex-direction: row;
      justify-content: space-between;
    }

    & button {
      flex: 1;
      width: 100%;
    }
  }
}

.faqs {
  && {
    margin-bottom: var(--spacing-5);

    & p,
    & a {
      display: inline;
    }
    & a > p {
      font-weight: 700;
    }
  }
}

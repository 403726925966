.backButtonWrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2);
  padding: var(--spacing-6) 0 var(--spacing-6) 0;
  height: 32px;

  @media (--mobile) {
    padding: 14px 0 26px 0;
  }
}

.closeIcon {
  display: inline-flex;
  align-items: center;
  color: black;
  text-decoration: none;

  & button {
    cursor: pointer;
    padding: 0;
    margin-left: -8px;
  }
  & svg {
    font-size: 20px;
    vertical-align: middle;
  }
  & span {
    font-size: 14px;
    vertical-align: middle;
  }
}
.back {
  @media (--mobile) {
    display: none;
  }
}
.buyNowText {
  padding-left: 4px;
}

.divider {
  height: 20px;
  width: 1px;
  background-color: var(--color-tarmac-grey-100);
  margin: 0 var(--spacing-2);
  @media (--mobile) {
    height: 18px;
    margin: var(--spacing-2) var(--spacing-2) var(--spacing-2) 0px;
  }
}

.modalContainer {
  @media (--tablet) {
    width: 650px;
    height: 635px;
  }
}

.modalBody {
  overflow: auto;
}

.wizard {
  & :global(hr) {
    background: var(--color-neutral-300);
    height: 1px;

    margin: 24px 0;
    padding: 0;
    border: none;
  }

  & :global(.fields) {
    gap: 12px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    margin: 16px 0 24px;

    & * {
      grid-column-start: 1;
      grid-column-end: 2;
    }
  }
}

.screenWrapper {
  position: fixed;
  z-index: 9994;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  overflow: auto;
  background: var(--color-overlay);

  z-index: 0;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s;

  &[data-is-active='true'] {
    opacity: 1;
    pointer-events: auto;

    & .modal {
      margin-top: 0px;
    }
  }
  &([data-is-debug-on='true']) {
    z-index: 9994;
  }

  &[data-is-active='true']:not([data-is-debug-on='true']) {
    z-index: 9995;
  }
}

.modal {
  position: relative;
  display: flex;
  flex-direction: column;
  background: var(--color-championship-white);

  overscroll-behavior: none;

  margin-top: 10px;
  transition: opacity 0.2s;
}

.closeButton {
  & svg {
    fill: var(--color-neutral-700);
  }
}

.header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--color-neutral-300);
  padding: 0 var(--spacing-5);
  align-items: center;
  height: 64px;
  min-height: 64px;

  @media (--tablet) {
    padding: 0 var(--spacing-6);
  }
  @media (--tablet-l) {
    padding: 0 var(--spacing-10);
  }
}

.modalBody {
  overflow-y: auto;
  padding: 0 var(--spacing-5);

  @media (--tablet) {
    padding: 0 var(--spacing-6);
  }

  @media (--tablet-l) {
    padding: 0 var(--spacing-10);
  }
}

.modalFooter {
  padding: var(--spacing-5);

  @media (--tablet) {
    padding: var(--spacing-6);
  }
  @media (--tablet-l) {
    padding: var(--spacing-8) var(--spacing-10);
  }
}

.centered {
  width: 100%;

  @media (--tablet-l) {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 800px;
  }
}

& [data-tenant='rac'] {
  .header {
    background: var(--color-primary-500);
    border-bottom: 1px solid var(--color-primary-500);
  }

  .title {
    color: var(--color-championship-white);
  }

  .closeButton {
    & svg {
      fill: var(--color-championship-white);
    }
  }
}

.clickableHeader {
  cursor: pointer;
}

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  overflow: hidden;
  width: 100%;
  height: 64px;
  max-width: 100vw;
  @media (--tablet) {
    height: 56px;
  }
  @media (--tablet-l) {
    height: auto;
    overflow: visible;
  }
  @media (--desktop) {
    background: var(--color-primary-700);
  }
}
.headerInner {
  padding-top: var(--spacing-3);
  padding-bottom: var(--spacing-3);
  width: 100%;
  background: var(--color-primary-700);

  & > div {
    width: 100%;
    align-items: flex-end;
  }

  @media (--tablet-l) {
    padding-right: var(--spacing-5);
  }

  @media (--desktop-s) {
    padding-right: var(--spacing-2);
  }

  @media (--desktop-l) {
    padding-right: var(--spacing-10);
  }
}

.colLeft {
  display: flex;
  gap: var(--spacing-12);
  align-items: center;
}

.colRight {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: white;
  text-decoration: underline;

  p {
    margin: 0;
    cursor: pointer;
  }

  @media (--tablet-l) {
    gap: var(--spacing-4);
  }

  @media (--desktop) {
    gap: var(--spacing-1);
  }
}

.helpText {
  display: none;

  @media (--tablet) {
    display: block;
  }
}

.helpTextShort {
  @media (--tablet) {
    display: none;
  }
}

.logo {
  display: inline-block;
  height: 32px;
  & > svg {
    width: auto;
    height: 32px;
    @media (--mobile) {
      max-width: 200px;
    }
  }

  @media (--desktop) {
    margin-left: var(--spacing-3);
  }
}

.closeIcon {
  margin-left: var(--spacing-2);
  cursor: pointer;
  color: var(--color-championship-white);

  @media (--tablet) {
    margin-left: var(--spacing-5);
  }
}

.modalContainer {
  @media (--tablet) {
    width: 650px;
    height: 635px;
  }
}

.modalBody {
  overflow: auto;
}

.progressBarWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2);
  padding-top: var(--spacing-6);
}

.progressBar {
  position: relative;
  padding-bottom: var(--spacing-8);
  border-bottom: 1px solid var(--color-neutral-300);
}

.progressBarTitleWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.progressBarSteps {
  text-decoration: none;
  position: relative;
  display: grid;
  grid-row-gap: var(--spacing-2);
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  flex: 1;
  cursor: pointer;
}
.backNavDisabled {
  cursor: default;
}

.progressBarBubble {
  grid-column: 1 / span 2;
  height: 24px;
  width: 24px;
  border: 2px solid var(--color-mica-blue-500);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  color: var(--color-tarmac-grey-100);
  background-color: var(--color-mica-blue-500);
  z-index: 3;
}

.progressBarTitle {
  grid-column: 1 / span 2;
  width: 100%;
  font-weight: 700;
  color: var(--color-tarmac-grey-700);
  @media (--tablet-l) {
    text-align: center;
  }
}

.progressBarSubTitle {
  color: var(--color-tarmac-grey-500);
  font-weight: 400;
}

.progressStepIndex {
  color: var(--color-tarmac-grey-700);
  margin-bottom: var(--spacing-6);
  text-align: center;
  font-weight: 400;
}

.progressBarSteps:first-of-type {
  grid-template-columns: 1fr;
  flex: 0.5;
  .progressBarBubble {
    justify-self: flex-start;
  }
  .progressBarTitle {
    text-align: start;
  }
}

.progressBarSteps:last-of-type {
  grid-template-columns: 1fr;
  flex: 0.5;
  .progressBarBubble {
    justify-self: flex-end;
  }
  .progressBarTitle {
    text-align: end;
  }
}

.progressBarStepsMobile {
  width: 100%;
  position: absolute;
  top: -45px;
  margin: 0 auto;
  .progressBarSteps {
    padding: 0 var(--spacing-6);
    display: flex;
    .progressBarBubble {
      justify-self: flex-end;
    }
    .progressBarTitle {
      text-align: flex-start;
    }
  }
  & div {
    justify-content: center;
    align-items: flex-start;
    background-color: transparent;
    padding-bottom: 0;
    border: none;
    width: 100%;
    z-index: 4;
    margin: 0;
  }
  & svg {
    background-color: var(--color-mica-blue-50);
  }
  & div div:first-child {
    margin-bottom: 3px;
  }
  & div div:last-child {
    background-color: var(--color-championship-white);
    border-bottom: 1px solid var(--color-mica-blue-50);
  }
  .progressBarBubble {
    margin-right: var(--spacing-3);
  }
  .progressBarTitleWrapper {
    border-bottom: 1px solid var(--color-tarmac-grey-50);
  }
  .progressBarSteps {
    align-items: center;
  }
}

.progressBarStepsDesktop {
  display: flex;
  & .progressBarSteps::before,
  & .progressBarSteps::after {
    position: absolute;
    content: '';
    border-bottom: 2px solid var(--color-mica-blue-500);
    width: 100%;
    top: calc((var(--spacing-6) / 2) - (var(--spacing-1) / 2));
    z-index: 2;
  }
  .progressBarTitleWrapper {
    display: flex;
    grid-column: 1 / span 2;
  }
  .progressBarTitle {
    margin-bottom: 4px;
  }
}

.progressBarSteps::before {
  left: -50%;
}

.progressBarSteps::after {
  left: 50%;
}

.progressBarSteps:first-child::before,
.progressBarSteps:last-child::after {
  content: none;
}

.progressBarSteps:first-child::after {
  left: 0;
  margin-left: 100%;
}

.progressBarSteps:last-child::before {
  left: 0;
  margin-left: -100%;
  width: 200%;
}

.progressBarSteps[data-disabled='true'] {
  pointer-events: none;
  .progressBarBubble {
    border: 2px solid var(--color-tarmac-grey-50);
    color: var(--color-tarmac-grey-900);
    background-color: var(--color-tarmac-grey-50);
  }
  &.progressBarSteps::before {
    border-bottom: 2px solid var(--color-tarmac-grey-100);
  }
  &.progressBarSteps::after {
    border-bottom: 2px solid var(--color-tarmac-grey-100);
  }
}

.progressBarSteps[data-current='true'] {
  .progressBarBubble {
    background-color: var(--color-championship-white);
    color: var(--color-mica-blue-500);
  }
}

@media (min-width: 1024px) {
  .progressStepIndex,
  .progressBarStepsMobile {
    display: none;
  }
}

@media (max-width: 1023px) {
  .progressBarWrapper {
    background-color: var(--color-mica-blue-50);
    padding-top: var(--spacing-3);
    margin: 0 calc(var(--spacing-6) * -1);
    width: 100vw;
  }

  .progressBar {
    border-bottom: none;
    padding-bottom: 10px;
  }

  .progressBarSteps {
    display: flex;
  }

  .progressBarStepsDesktop {
    .progressBarTitle,
    .progressBarBubble {
      display: none;
    }
  }

  .progressBarSteps:last-of-type {
    grid-template-columns: 1fr;
    flex: 0.5;
    .progressBarBubble {
      justify-self: flex-start;
    }
    .progressBarTitle {
      text-align: start;
    }
  }
}

@media (--mobile) {
  .progressBarWrapper {
    margin: 0 calc(var(--spacing-5) * -1);
  }
}
